<template>
	<div class="cash-box">
	<el-row type="flex" justify="space-between" class="cash-item" >
		<span>现金券</span>
		<div @click="clickOpenCash">
			<span>未选</span>
			<span class="iconfont icon-jiantouyoubian"></span>
		</div>
	</el-row>
	<el-row type="flex" justify="space-between" class="cash-item">
		<span>优惠券</span>
		<div @click="clickOpenDiscounp">
			<span>未选</span>
			<span class="iconfont icon-jiantouyoubian"></span>
		</div>
	</el-row>
	<desModal :titleRight="cashOpen" title="使用现金券" class="cash-modal" widthNum="500px" @clickDelAction="delDetail">
		<div class="cash-list">
			<el-row type="flex">
				<el-input v-model="input" placeholder="输入10位数券码激活现金券" style="width:70%"></el-input>
				<button class="active-cash">激活现金券</button>
			</el-row>
			<div class="cash-item-scroll">
				<div class="cash-item" v-for="(item,a) in [1,2,3,4,5]" :key="a">
					<el-row type="flex" class="demo">
						<div>
							<span class="cash-number">6</span>
						</div>
						<div class="card-right">
							<div>
							<div>
								<span class="cash-yuan">现金券</span>
								<span class="travel-yuan">旅游面值100元</span></div>
						    </div>
							<div class="quan-number">券号:0623183121</div>
							<div class="exp-date">有效期:2020.12.10-2021.12.31</div>
							<el-row type="flex" justify="space-between">
								<span class="no-use">未使用</span>
								<span class="iconfont icon-chalv-duoxuankuang icon-cir"></span>
								<span class="iconfont icon-kongxingou icon-cirtwo"></span>
							</el-row>
							</div>
				 </el-row>
				</div>
			</div>
			<el-row type="flex" justify="center">
			<el-button type="danger" class="button mt25" style="width: 120px;" @click="clickAction">确定</el-button>
			</el-row>
			<personNoData msg="暂无数据"/>
		</div>
	</desModal>
	<desModal :titleRight="discounpOpen" title="使用优惠券" class="cash-modal" widthNum="500px" @clickDelAction="delDetailDis">
		<div class="cash-list">
			<el-row type="flex">
				<el-input v-model="input" placeholder="输入10位数券码激活优惠券" style="width:70%"></el-input>
				<button class="active-cash">激活优惠券</button>
			</el-row>
			<div class="cash-item-scroll">
				<div class="cash-item" v-for="(item,a) in [1,2,3,4,5]" :key="a">
					<el-row type="flex" class="demo">
						<div>
							<span class="cash-number">6</span>
						</div>
						<div class="card-right">
							<div>
							<div>
								<span class="cash-yuan">现金券</span>
								<span class="travel-yuan">旅游面值100元</span></div>
						    </div>
							<div class="quan-number">券号:0623183121</div>
							<div class="exp-date">有效期:2020.12.10-2021.12.31</div>
							<el-row type="flex">
								<span class="no-use">未使用</span>
							    <div class="use-rule use-box">
									<span class="mr5">使用规则</span>
									<span class="iconfont icon-jiantoushangmian"></span>
									<span class="iconfont icon-jiantouxiamian"></span>
								</div>
							</el-row>
							</div>
				 </el-row>
				 <div class="pd10 gray9 use-box2">使用规则</div>
				</div>
			</div>
			<el-row type="flex" justify="center">
			<el-button type="danger" class="button mt25" style="width: 120px;" @click="clickAction">取消</el-button>
			</el-row>
			<personNoData msg="暂无数据"/>
		</div>
	</desModal>
	</div>
</template>

<script>
	import desModal from './desModal.vue';
	import personNoData from './personNoData.vue';
	export default {
	    name:'Cash',
		components:{
			desModal,
			personNoData
		},
		// props: ['cashOpen'],
		data() {
		    return {
		      input: '',
			  cashOpen:false,
			  discounpOpen:false
		    }
		},
	    created(){
	    },
	    mounted(){
	    },
	    methods:{ 
			clickOpenCash(){
				this.cashOpen=true;
			},
			delDetail(){
				this.cashOpen=false;
			},
			clickOpenDiscounp(){
				this.discounpOpen=true;
			},
			delDetailDis(){
				this.discounpOpen=false;
			}
	    }
	}
</script>

<style>
</style>
