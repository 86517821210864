<template>
	<div class="flight-comfirm">
		<div class="pb10 mt10">
			<div class="box-title">酒店信息</div>
			<div class="seat-ebox">
				<el-row type="flex" justify="space-between">
					<el-row type="flex">
						<div class="time-box clearfix">
							<div class="day">{{ HotelBookInfo.dateInfo.inDateDay }}</div>
							<div class="fl gray9 ft12">
								<p>{{ HotelBookInfo.dateInfo.inDateWeek }}</p>
								<p>{{ HotelBookInfo.HotelSearchInfo.CheckInDateStr }}</p>
							</div>
						</div>
						<span class="iconfont icon-bed icon-34fuwuchuang"></span>
						<div class="time-box clearfix">
							<div class="day">{{ HotelBookInfo.dateInfo.outDateDay }}</div>
							<div class="fl gray9 ft12">
								<p>{{ HotelBookInfo.dateInfo.outDateWeek }}</p>
								<p>{{ HotelBookInfo.HotelSearchInfo.CheckOutDateStr }}</p>
							</div>
						</div>
						<span class="name ml20" :title="HotelBookInfo.HotelInfo.HotelName">
							{{ HotelBookInfo.HotelInfo.HotelName }}({{ HotelBookInfo.HotelInfo.HotelEName }})
						</span>
					</el-row>
					<div class="cancel">
						<a href="javascript:;" class="txt">
							{{ HotelBookInfo.HotelRoomPriceInfo.RoomPriceInfos[0].CancelPolicyStr }}
							<span class="iconfont icon-errow icon-zhongyaotishi"></span>
						</a>
						<div class="c-detail">
							<i class="trg1" style="left:initial;right:0px"></i>
							<i class="trg2" style="left:initial;right:0px"></i>
							<ul>
								<li>{{ HotelBookInfo.HotelRoomPriceInfo.RoomPriceInfos[0].HotelCancelPolicy.PolicyDetails }}</li>
							</ul>
						</div>
					</div>
				</el-row>
			</div>
			<div class="message-box clearfix">
				<div>
					<em class="ft18 gray3">订单总金额：</em>
					<em class="price">
						￥
						<em class="price">{{ HotelBookInfo.HotelRoomPriceInfo.RoomPriceInfos[0].TotalAmount }}</em>
					</em>
				</div>
				<div>
					房价：￥{{ HotelBookInfo.HotelRoomPriceInfo.RoomPriceInfos[0].AveragePrice }}×{{ HotelBookInfo.HotelSearchInfo.Day }}晚×{{
						HotelBookInfo.HotelSearchInfo.RoomCount
					}}间
				</div>
				<!-- <div>税费：￥0×{{HotelBookInfo.HotelSearchInfo.CheckOutDateStr}}晚×{{HotelBookInfo.HotelSearchInfo.CheckOutDateStr}}间</div> -->
			</div>
		</div>
	</div>
</template>

<script>
var datehelper = require('@/utils/datehelper.js');
var that;
export default {
	name: 'hotelMessage',
	props: ['HotelBookInfo', 'priceInfo'],
	data() {
		return {
			
		};
	},
	created() {
		that = this;
		// var HotelSearchInfo=that.HotelBookInfo.HotelSearchInfo;
		console.log(that.HotelBookInfo);
		// var inDayWeek=HotelSearchInfo.CheckInDate.toString("WWW");
		// console.log(inDayWeek);
	}
};

</script>

<style></style>
