<template>
	<div class="int-content hoel-comfirm">
		<ul class="reserve-flows flex-start flexbox">
			<li :class="(item.current == true ? 'current ' : '') + 'ticket-box  flexbox'" v-for="(item, index) in progress" :key="index">
				<span class="flows-line"></span>
				<a class="flows-text">{{ item.name }}</a>
			</li>
		</ul>
		<el-form ref="form" :model="form">
			<div class="flight-comfirm">
				<!--预定的酒店信息-->
				<hotelMessage :priceInfo="priceInfo" :HotelBookInfo="HotelBookInfo"></hotelMessage>
				<div class="pb10 mt10">
					<div class="box-title">
						<el-row type="flex" justify="space-between">
							<span>入住信息</span>
							<div class="explain-txtbox clearfix">
								<!-- <div class="explain-list">
									<a>
										入住说明
										<i class="iconfont icon-mark"></i>
									</a>
									<div class="explain-box">
										<em class="trig1">◆</em>
										<em class="trig2">◆</em>
										<div class="explain-content"></div>
									</div>
								</div> -->
							</div>
						</el-row>
					</div>
					<div class="seat-ebox">
						<div v-for="(item, index) in form.HotelPassengerList" :key="index">
							<div class="tit">客房{{ item.RoomIndex }}：{{ item.RoomName }}</div>
							<el-row class="list" type="flex" align="middle" v-for="(PassengerItem, index2) in item.PassengerListModel" :key="index2">
								<span class="num">{{ item.RoomIndex }}</span>
								<el-row class="item-list" type="flex" align="middle">
									<div class="item-tit name mr5">姓名</div>
									<el-row class="inp-inner">
										<el-input v-model="PassengerItem.Man_xing" placeholder="名（拼音或英文）" style="width:140px"></el-input>
										<el-input v-model="PassengerItem.Man_ming" placeholder="姓（拼音或英文）" style="width:140px"></el-input>
									</el-row>
								</el-row>

								<el-row class="item-list guest ml15" type="flex" align="middle">
									<div class="item-tit name mr5">乘客类型</div>
									<div class="inp-inner clearfix">
										<div class="item-inp">
											<el-select v-model="PassengerItem.Man_type_p" placeholder="类型" style="width:100px">
												<el-option label="成人" value="ADT"></el-option>
												<el-option label="儿童" value="CHD"></el-option>
											</el-select>
										</div>
									</div>
								</el-row>
								<el-row class="item-list nationality ml15" type="flex" align="middle">
									<div class="item-tit name mr5">国籍</div>
									<div class="inp-inner clearfix">
										<!-- <el-input placeholder="国籍" style="width:100px" v-model="PassengerItem.Nationality"></el-input> -->

										<el-cascader
											class="confirm-cn"
											popper-class="confirm-icong"
											v-model="PassengerItem.Nationality"
											:options="Countrys"
											:show-all-levels="false"
											filterable
										></el-cascader>
									</div>
								</el-row>
								<!-- <el-checkbox v-model="checked" class="ml15">主入住人</el-checkbox> -->
							</el-row>
						</div>
					</div>
				</div>
				<div class="pb10 mt10" v-show="false">
					<div class="box-title">现金券</div>
					<Cash/>
				</div>
				
				<div class="pb10 mt10">
					<div class="box-title">
						其他需求
						<i class="ft12 gray9">以下需求会发送到酒店，但无法保证一定满足，实际以酒店当天安排为准（可多选）</i>
					</div>
					<div class="seat-ebox order-checkbox" style="margin-left: 60px;">
						<el-checkbox-group v-model="form.hotelRemarkChecked">
							<div>
								<el-checkbox v-for="(item, index) in hotelRemark" :label="item.name" :key="index">
									{{ item.name }}
									<i class="gray9 ft12">{{ item.tips }}</i>
									<!-- <i class="gray9 ft12" v-if="index == 5">（晚到请务必申明，以免酒店无法正常保留房间，造成客人无法入住）</i>
									<i v-if="index == 8" class="gray9 ft12">（酒店可能会另外收取加床费用）</i> -->
								</el-checkbox>
							</div>
						</el-checkbox-group>
						<div class="pt10 mr15"><span class="ft16">其他:</span><el-input type="textarea" :rows="6" placeholder="其他要求,请用英文填写，否则系统无法识别" v-model="form.hoteladdremark"></el-input></div>
					</div>
				</div>
				<div class="pb10 mt10">
					<div class="box-title clearfix">
						<span class="fl">联系人信息</span>
						<i class="ft12 gray9">（请准确填写联系人信息，重要信息我们会通过手机短信或邮件方式通知您）</i>
					</div>
					<el-row class="seat-ebox" type="flex" justify="space-between">
						<el-row type="flex" class="ft14" align="middle">
							<div>
								<span>
									<i class="red">*</i>
									联系人：
								</span>
							</div>
							<el-rol :span="16"><el-input v-model="form.linkman" size="middle"></el-input></el-rol>
						</el-row>
						<el-row type="flex" class="ft14" align="middle">
							<div>
								<span>
									<i class="red">*</i>
									国家区号-手机号码：
								</span>
							</div>
							<el-rol :span="16" align="middle">
								<el-row type="flex" align="middle">
									<el-rol><el-input size="middle" v-model="form.area"></el-input></el-rol>
									<span class="ml10 mr10">-</span>
									<el-input size="middle" v-model="form.phone"></el-input>
								</el-row>
							</el-rol>
						</el-row>
						<el-row type="flex" class="ft14" align="middle">
							<div><span>联系邮箱：</span></div>
							<el-rol :span="16"><el-input size="middle" v-model="form.email"></el-input></el-rol>
						</el-row>
					</el-row>
				</div>
				<div class="pb10 mt10">
					<div class="box-title">
						附加信息
						<i class="ft12 gray9">（选填）</i>
					</div>
					<div class="seat-ebox"><el-input type="textarea" :rows="6" v-model="form.remark"></el-input></div>
				</div>
			</div>

			<div class="text-c btn-box">
				<router-link class="return-btn" to="/Hotel/list">返回</router-link>
				<el-button type="danger" class="btn next-btn" @click="bindNext">下一步</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
import hotelMessage from './component/hotelMessage.vue';
import { getAccountInfo, getHotelBookingFormInfo, setHotelBookingFormInfo } from '@/utils/localdatas';
import { checkPassenger } from '@/api/order';
import Cash from '../../components/cash.vue';
var util = require('@/utils/util.js');
var yqfCommon = require('@/utils/yqfCommon.js'); //通用类库
var hotelApi = require('@/api/hotelApi.js');
var destApi = require('@/api/destApi.js'); //目的地
var that;

export default {
	components: {
		hotelMessage,
		Cash
	},
	data() {
		return {
			cashOpen:false,
			hotelRemark: [
				{
					name: '禁烟/No-Smoking',
					tips: '',
					isChecked: false
				},
				{
					name: '吸烟/Smoking-Room',
					tips: '',
					isChecked: false
				},
				{
					name: '蜜月布置/Honeymoon',
					tips: '',
					isChecked: false
				},
				{
					name: '高层楼/Higher-Floor',
					tips: '',
					isChecked: false
				},
				{
					name: '晚点入住/Late-Arrival',
					tips: '',
					isChecked: false
				},
				{
					name: '提前入住/Early-Arrival',
					tips: '（晚到请务必申明，以免酒店无法正常保留房间，造成客人无法入住）',
					isChecked: false
				},
				{
					name: '原房续住住/Extended stay in original room',
					tips: '',
					isChecked: false
				},
				{
					name: '请提供内部相通的房间/Connecting rooms',
					tips: '',
					isChecked: false
				},
				{
					name: '加婴儿床/Baby Cot',
					tips: '（酒店可能会另外收取加床费用）',
					isChecked: false
				}
			],
			progress: [
				{
					name: '选择酒店',
					current: true
				},
				{
					name: '选择房型',
					current: true
				},
				{
					name: '填写资料',
					current: true
				},
				{
					name: '确定资料'
				},
				{
					name: '提交预订'
				}
			],
			form: {
				linkman: '',
				area: '0086',
				phone: '',
				email: '',
				remark: '',
				isseat: 0,
				hotelRemarkChecked: [],
				hoteladdremark: '',

				HotelPassengerList: []
			},
			HotelBookInfo: {},
			priceInfo: {
				TotalPrice: 0,
				AdultPrice: 0,
				AdultTax: 0,
				ChildPrice: 0,
				ChildTax: 0,
				AdultCount: 1,
				ChildCount: 0
			},
			Countrys: []
		};
	},
	created() {
		console.log(this.$route.query);
		that = this;

		var accountInfo = getAccountInfo();

		if (accountInfo != null) {
			that.form.linkman = accountInfo.Name;
			that.form.email = accountInfo.Email;
			that.form.phone = accountInfo.Mobile;
			// that.form.linkman=accountInfo.Name;
		}
		// that.form = getHotelBookingFormInfo();
		// console.log(that.form);
		getHotelBookInfo();
		getCountrys();
		$(window).scrollTop("0");
	},
	methods: {
		clickOpenCash(){
			this.cashOpen=true;
			console.log(11);
		},
		clickDelCash(){
			this.cashOpen=false;
		},
		//下一步
		bindNext() {
			console.log(that.form);
			let HotelPassengerList = that.form.HotelPassengerList;
			for (var j = 0; j < HotelPassengerList.length; j++) {
				var PassengerList = HotelPassengerList[j].PassengerListModel;

				for (var i = 0; i < PassengerList.length; i++) {
					let Man_xing = PassengerList[i].Man_xing;
					let Man_ming = PassengerList[i].Man_ming;
					let Birthday = PassengerList[i].Birthday;
					let Man_type_p = PassengerList[i].Man_type_p;
					let tips = '请填写旅客' + (i + 1);
					if (util.isNullOrEmpty(Man_xing)) {
						that.$alert(tips + '的姓');
						return;
					}
					if (util.isNullOrEmpty(Man_ming)) {
						that.$alert(tips + '的名');
						return;
					}
					// if (util.isNullOrEmpty(Birthday)) {
					// 	that.$alert(tips + '的生日');
					// 	return;
					// }
					if (Man_type_p == '成人') {
						PassengerList[i].Man_type_p = 'ADT';
					} else if (Man_type_p == '小孩') {
						PassengerList[i].Man_type_p = 'CHD';
					}
					
					PassengerList[i].Man_xingming = Man_xing + '/' + Man_ming;
					
					if(!util.isNullOrEmpty(Birthday)){
						let age=yqfCommon.getAgeByBirthday(Birthday);
						console.log(age);
						if(age<18){
							PassengerList[i].Man_type_p='CHD';
						}
						else{
							PassengerList[i].Man_type_p='ADT';
						}
					}
					let Nationality = PassengerList[i].Nationality;
					console.log(Nationality);
					if (Nationality instanceof Array) {
						PassengerList[i].Nationality = Nationality[0];
					}
				}
				HotelPassengerList[j].PassengerListModel = PassengerList;
			}
			if (util.isNullOrEmpty(that.form.phone)) {
				that.$alert('请填写联系人手机号码');
				return;
			}
			
			let parm = {
				HotelPassengerList: JSON.stringify(HotelPassengerList),				
				hotelVendor:'',
				ordertype:'hotel',
				HotelSearchInfoJSON:JSON.stringify(that.HotelBookInfo.HotelSearchInfo),
			};
			that.btnLoading = true;
			checkPassenger(parm, function(result) {
				that.btnLoading = false;
				if (result.code == 0) {
					that.form.HotelPassengerList = HotelPassengerList;					
					setHotelBookingFormInfo(that.form);					
					that.$router.push({ path: '/Hotel/confirm' });
				} else {
					that.$alert(result.msg);
				}
			});
		}
	}
};
//
function getHotelBookInfo() {
	hotelApi.GetHotelBookInfo(null, function(result) {
		// console.log(result);;
		// that.isShowloading=false;
		if (result.code == 0) {
			that.HotelBookInfo = result.data.HotelBookInfo;
			that.HotelBookInfo.dateInfo = result.data.dateInfo;

			getHotelPassengerList(that.HotelBookInfo.HotelRoomPriceInfo.RoomPriceInfos[0].RoomName);
		} else {
			that.$alert(result.msg, {
				confirmButtonText: '确定',
				callback: action => {
					that.$router.push({ path: '/Hotel' });
				}
			});
		}
	});
}
//旅客信息
function getHotelPassengerList(roomName) {
	let form = getHotelBookingFormInfo();
	// if (form != null) {
	// 	form.ho
	// 	that.form = form;
	// }
	console.log(form);
	// if (util.isNullOrEmpty(form)) {
	hotelApi.GetHotelPassengerList({ roomName: roomName }, function(result) {
		// console.log(result);;
		if (result.code == 0) {
			var HotelPassengerList = result.data.HotelPassengerList;
			console.log(HotelPassengerList);
			that.form.HotelPassengerList = HotelPassengerList;
		}
	});
	// } else {

	// setPriceInfo();
	// }
}
function getCountrys() {
	destApi.GetCountrys({}, function(result) {
		// console.log(result);;
		if (result.code == 0) {
			that.Countrys = result.data.Countrys;
		}
	});
}
</script>

<style lang="scss">
@import '../../style/IntlAir/index.scss';
@import '../../style/IntlAir/comfirm.scss';
@import '../../style/Hotel/hotel.scss';
</style>
